import { HTTP } from '../../axios/axios'
let data

async function getAll (lang, page) {
  try {
    const res = await HTTP.get('/faq/pages/' + page, { headers: { 'accept-language': lang } })
    data = res.data
    let arr = []
    for (const item of data) {
      for (const subitem of item.qaBlocks) {
        subitem.parentId = item._id
        subitem.parent = item.title
      }
      arr = [...arr, { title: item.title, _id: item._id, isString: true }, ...item.qaBlocks]
    }
    return arr
  } catch (error) {
    console.log(error)
  }
}
async function addCategory (lang, title) {
  return await HTTP.post('/faq', { page: data[0].page, lang, title, qaBlocks: [] })
}
async function deleteCategory (item) {
  return await HTTP.delete('/faq/' + item._id)
}
async function changeCategory (id, title) {
  return await HTTP.patch('/faq/' + id, { title })
}
async function addOne (qa, lang) {
  const id = qa.id
  delete qa.id
  return await HTTP.patch('/faq/add-one/' + id, qa)
}
async function update (qa, id) {
  const faq = data.find(x => x._id === qa.parentId)
  return await HTTP.patch('/faq/' + qa.parentId, { title: qa.parent, qaBlocks: faq.qaBlocks })
}
async function deleteOne (qa) {
  const faq = data.find(x => x._id === qa.parentId)
  faq.qaBlocks = faq.qaBlocks.filter(x => x._id !== qa._id)
  return await HTTP.patch('/faq/' + qa.parentId, { title: qa.parent, qaBlocks: faq.qaBlocks })
}
async function deleteMany (arr) {
  for (const faq of data) {
    try {
      faq.qaBlocks = faq.qaBlocks.filter(x => arr.indexOf(x._id) === -1)
      await HTTP.patch('/faq/' + faq._id, { title: faq.title, qaBlocks: faq.qaBlocks })
    } catch (error) {
      console.log(error)
    }
  }
}
async function changeOrder (allBlocks, oldId) {
  let startId = 0
  let endId = 0
  for (let i = 0; i < allBlocks.length; i++) {
    if (i < oldId && allBlocks[i].isString) startId = i
    if (i > oldId && allBlocks[i].isString) {
      endId = i
      break
    }
  }
  const title = allBlocks[startId].title
  const qaBlocks = allBlocks.slice(startId + 1, endId)
  return await HTTP.patch('/faq/' + data[0]._id, { qaBlocks, title })
}

export default { getAll, addCategory, deleteCategory, changeCategory, deleteMany, addOne, update, deleteOne, changeOrder }
